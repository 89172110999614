import * as consts from "../consts";
import axios from "axios";

function handleError(exception, message, dispatch) {
  if (process.env.NODE_ENV === "development") {
    console.log("TB-CARDBUILDER:", exception);
  }
  if (message && dispatch) {
    addAlert(
      {
        message: message,
        type: consts.ALERT_TYPE_ERROR,
      },
      dispatch
    );
  }
}

export function addAlert(alert, dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_ADD_ALERT,
      payload: alert,
    });
    resolve();
  });
}

export function dismissAlert(dispatch) {
  return new Promise((resolve) => {
    dispatch({
      type: consts.ACTION_DISMISS_ALERT,
    });
    resolve();
  });
}

export function getOrder(orderID, dispatch) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${consts.SERVICE_URL}/Order/orderdetail/${orderID}`, {})
      .then((response) => {
        let order = response.data;
        dispatch({
          type: consts.ACTION_INITIALIZE_ORDER,
          payload: order,
        });
        resolve(order);
      })
      .catch((exception) => {
        handleError(exception, `Unable to retrieve order`, dispatch);
        reject(exception);
      });
  });
}
