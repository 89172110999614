export const SERVICE_URL = process.env.SERVICE_URL;
export const V4_SERVICE_URL = process.env.V4SERVICE_URL;
export const SITE_URL = process.env.SITE_URL;
export const BLOB_URL = process.env.BLOB_URL;
export const ATTACHMENT_SERVICE_URL = process.env.ATTACHMENT_SERVICE_URL;

export const ACTION_ADD_ALERT = "ACTION_ADD_ALERT";
export const ACTION_DISMISS_ALERT = "ACTION_DISMISS_ALERT";
export const ACTION_INITIALIZE_ORDER = "ACTION_INITIALIZE_ORDER";

export const ALERT_TYPE_ERROR = "Error";
export const ALERT_TYPE_SUCCESS = "Success";
