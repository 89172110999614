import React, { useEffect, useState, useContext } from "react"; // eslint-disable-line no-unused-vars
import { makeStyles } from "@material-ui/core/styles";
import { Button, Backdrop, CircularProgress } from "@material-ui/core";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import * as consts from "../consts";
import { State } from "../store/state";
import { dismissAlert, getOrder } from "../store/actions";

import Alert from "./presentational/alert";

const App = (props) => {
  const generateClassName = createGenerateClassName({
    seed: "tbprintcards",
  });

  const theme = createMuiTheme(props.theme);
  const useStyles = makeStyles((theme) => ({
    main: { paddingTop: theme.spacing(2) },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#ffffff",
    },
    logo: {
      textAlign: "center",
      "& img": {
        margin: "16px auto 16px auto",
      },
    },
    header: {
      fontSize: "18px",
      textAlign: "center",
      margin: "5px auto 20px auto",
    },
    cardImage: {
      textAlign: "center",
      "& img": {
        margin: "0 auto 16px auto",
      },
    },
    to: {
      width: "400px",
      margin: "auto",
    },
    from: {
      width: "400px",
      margin: "auto",
    },
    message: {
      width: "400px",
      margin: "12px auto 0px auto",
    },
    separator: {
      borderTop: "solid 1px #dedede",
      width: "410px",
      margin: "20px auto 10px auto",
    },
    description: {
      width: "400px",
      margin: "16px auto 16px auto",
    },
    list: {
      width: "400px",
      margin: "0 auto 16px auto !important",
      paddingLeft: "24px",
    },
    thankyou: {
      width: "400px",
      margin: "0 auto 16px auto",
    },
    instructions: {
      width: "400px",
      margin: "auto",
    },
    step: {
      margin: "16px 0px",
    },
    buttonstep: {
      margin: "16px 0px",
      "@media print": {
        display: "none",
      },
    },
    break: {
      display: "block",
      pageBreakAfter: "always",
      pageBreakBefore: "always",
      position: "relative",
    },
  }));
  const classes = useStyles();
  const { state, dispatch } = useContext(State);
  const [busy, setBusy] = useState(false);

  const getQueryParams = (params, url) => {
    let href = url;
    let reg = new RegExp("[?&]" + params + "=([^&#]*)", "i");
    let queryString = reg.exec(href);
    return queryString ? decodeURI(queryString[1]) : null;
  };

  useEffect(() => {
    setBusy(true);
    let orderID = getQueryParams("orderid", location.href);
    getOrder(orderID, dispatch).then(() => {
      setBusy(false);
    });
  }, []);

  const renderInstructions = () => {
    return (
      <div className={classes.instructions}>
        <div className={classes.logo}>
          <img
            src={`${consts.BLOB_URL}/templates/logonotagline.png`}
            alt="TisBest, a better gift a better world"
            width="135"
          />
        </div>
        <div className={classes.header}>
          Print or Save Your TisBest Charity Gift Cards
        </div>
        <div className={classes.buttonstep}>
          <Button
            fullWidth={true}
            variant="outlined"
            onClick={() => {
              window.print();
            }}
          >
            Open Print Dialog
          </Button>
        </div>
        <div className={classes.step}>
          To Print: select your printer from the dropdown options
          <br />
          To Save: select Save as PDF from the dropdown options
        </div>
        <div className={classes.step}>
          Tip: Open additional settings to exclude header and footer info, set
          paper size to Letter, and confirm double-sided printing is off. Please
          contact us at{" "}
          <a href="mailto:orders@tisbest.org">orders@tisbest.org</a> with any
          questions.
        </div>
      </div>
    );
  };

  const renderCard = (cardDetail) => {
    return (
      <div className={classes.break}>
        <div className={classes.logo}>
          <img
            src={`${consts.BLOB_URL}/templates/logonotagline.png`}
            alt="TisBest, a better gift a better world"
            width="135"
          />
        </div>
        <div className={classes.cardImage}>
          <img
            src={`${consts.V4_SERVICE_URL}/images/codes/${cardDetail.cardCode}?format=overlay-default-horizontal-with-text`}
            alt={cardDetail.cardCode}
            width="400"
          />
        </div>
        {cardDetail.to && cardDetail.to.length > 0 ? (
          <div className={classes.to}>A Gift for {cardDetail.to}</div>
        ) : null}
        {cardDetail.from && cardDetail.from.length > 0 ? (
          <div className={classes.from}>From {cardDetail.from}</div>
        ) : null}
        {cardDetail.message && cardDetail.message.length > 0 ? (
          <div className={classes.message}>{cardDetail.message}</div>
        ) : null}
        <div className={classes.separator}></div>
        <div>
          <div className={classes.description}>
            This TisBest Charity Gift Card is a gift. It entitles you to support
            charities you believe in.
          </div>
        </div>
        <div>
          <ol className={classes.list}>
            <li>Go to tisbest.org/spend</li>
            <li>Enter your unique code: {cardDetail.cardCode}</li>
            <li>Choose from over 1.5 million charities</li>
          </ol>
        </div>
        <div>
          <div className={classes.thankyou}>
            Thank you for helping to make a better world!
          </div>
        </div>
      </div>
    );
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline className={classes.main}>
          <Backdrop className={classes.backdrop} open={busy}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* 
          {state.alerts.length > 0 && (
            <Alert
              alert={state.alerts[0]}
              onDismiss={() => {
                dismissAlert(dispatch);
              }}
            />
          )} */}
          {/* <Grid xs={12} item container> */}
          {renderInstructions()}
          {state.order.cartItems &&
            state.order.cartItems.map((cartItem) => {
              return cartItem.cardDetails.map((cardDetail) => {
                return renderCard(cardDetail);
              });
            })}
          {/* </Grid> */}
        </ScopedCssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
};
export default App;
